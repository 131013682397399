import { useCoinUsdPrice, ZERO_BN } from '@trustpad/launchpad';

import { DBUserSaleContribution } from 'lib/types';
import * as React from 'react';
import { toBN } from 'web3-utils';

import { usePriceCoinGecko } from '~common/hooks/usePriceCoinGecko';
import { fNum } from '~utils';

// TODO: convert currency address
// TODO: show "locked in claimers value in $"
export function ContributionStats({
  contributions,
}: {
  contributions: DBUserSaleContribution[];
}) {
  const bnbPrice = parseFloat(useCoinUsdPrice('BNB'));
  const total = contributions.reduce(
    (acc, { contributedTotal, currencyAddress }) =>
      acc + contributedTotal * (currencyAddress === '' ? bnbPrice : 1),
    0,
  );
  const totalFCFS = contributions.reduce(
    (acc, { contributedFcfs, currencyAddress }) =>
      acc + contributedFcfs * (currencyAddress === '' ? bnbPrice : 1),
    0,
  );
  const lotteryWins = contributions.reduce((acc, c) => {
    if (c.isLotteryLevelWinner) {
      return acc + 1;
    }
    return acc;
  }, 0);
  const lotteryLoses = contributions.reduce((acc, c) => {
    if (['chance', 'prospect', 'affiliate'].includes(c.levelId) && !c.isLotteryLevelWinner) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    <div className="flex justify-around py-3">
      <Item label="Total contributed">${fNum(total)}</Item>
      <Item label="FCFS contributed">${fNum(totalFCFS)}</Item>
      <Item label="Lottery wins/loses">
        {lotteryWins} / {lotteryLoses}
      </Item>
    </div>
  );
}

function Item({ label, children }) {
  return (
    <div className="inline-flex flex-col items-center py-4 px-8 rounded-lg bg-dark3">
      <div className="flex items-center text-3xl">{children}</div>
      <div className="text-base opacity-50">{label}</div>
    </div>
  );
}
