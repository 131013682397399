import { useCallback, useEffect, useState } from 'react';

export default function useScreenWidth() {
  const [windowWidth, setWindowWidth] = useState(null);

  const isWindow = typeof window !== 'undefined';

  const getWidth = useCallback(
    () => (isWindow ? window.innerWidth : windowWidth),
    [isWindow, windowWidth],
  );

  const resize = useCallback(() => setWindowWidth(getWidth()), [getWidth]);

  useEffect(() => {
    if (isWindow) {
      setWindowWidth(getWidth());

      window.addEventListener('resize', resize, { passive: true });

      return () => window.removeEventListener('resize', resize);
    }
  }, [getWidth, isWindow, resize]);

  return windowWidth;
}
