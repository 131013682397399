import { chains, notEVMChains, useUserTier } from '@trustpad/launchpad';

import { useRouter } from 'next/router';
import { teaserPools } from 'pools';
import { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';

import { useUserExtraData } from '~common/providers/useUserExtraData';
import { ContributionStats } from '~features/landing/TabPools/ContributionStats';
import {
  getGroupedPools,
  PoolsGroup,
} from '~features/landing/TabPools/getGroupedPools';
import { GroupButtons } from '~features/landing/TabPools/GroupButtons';
import { SearchInput } from '~features/landing/TabPools/SearchInput';
import { usePoolSearch } from '~features/landing/TabPools/usePoolSearch';
import { PoolConfig } from '~features/pools';

import ChainDropdownFilter from './ChainDropdownFilter';
import { Group } from './Group';
import { PoolsTable } from './PoolsTable';
import { useChainFilter } from './useChainFilter';

function getChainName(chain: string) {
  return chains[chain] ? chains[chain].name : notEVMChains[chain].name;
}

export function TabPools({
  pools,
  updatePool,
}: {
  pools: PoolConfig[];
  updatePool: (pool: PoolConfig) => void;
}) {
  const router = useRouter();
  const { contributions, contributionsByPool } = useUserExtraData();
  const { tierState } = useUserTier();
  const { query, setQuery, foundPools, shouldFilter } = usePoolSearch(pools);
  const { chosenChain, setChosenChain, poolsByChain } = useChainFilter(pools);

  const contributedPools = contributions.map((c) => c.poolId);
  const groups = useMemo(
    () =>
      getGroupedPools(
        Object.values(pools).filter(
          (p) =>
            // Hide pools from low levels
            !p.showToMinLevelMultiplier ||
            tierState.tier.multiplier >= p.showToMinLevelMultiplier,
        ),
        contributedPools,
      ),
    [tierState.tier.multiplier, pools, contributedPools.length],
  );
  const [activeGroup, setActiveGroup] = useState<PoolsGroup>(
    (router.query['g'] as PoolsGroup) ?? PoolsGroup.Upcoming,
  );

  const urlGroup = router.query['g'] as PoolsGroup;
  useEffect(() => {
    if (
      Object.values(PoolsGroup).includes(urlGroup) &&
      urlGroup !== activeGroup
    ) {
      setActiveGroup(urlGroup as PoolsGroup);
    }
  }, [urlGroup]);

  function handleQueryChange(query: string) {
    setQuery(query);
    setChosenChain('');
  }

  const onClick = (group: PoolsGroup) => () => {
    router.replace({ query: { g: group, p: 0 } }, undefined, { scroll: false });
    setActiveGroup(group);
    setQuery('');
    setChosenChain('');
  };

  return (
    <section className="py-2">
      <Container>
        <div className="flex items-center flex-wrap gap-6 xll:gap-0 mb-12">
          <GroupButtons
            activeGroup={shouldFilter || chosenChain ? null : activeGroup}
            onClick={onClick}
            groups={groups}
            showContributed={groups.contributed.length > 0}
            showRefunded={tierState.tier.multiplier > 0}
          />
          <ChainDropdownFilter
            pools={pools}
            chosenChain={chosenChain}
            setChosenChain={setChosenChain}
            setQuery={setQuery}
            getChainName={getChainName}
          />
          <SearchInput value={query} onChange={handleQueryChange} />
        </div>

        {(() => {
          if (shouldFilter) {
            return <Group title="Search results" pools={foundPools} visible />;
          } else if (chosenChain) {
            return (
              <Group
                title={`Projects on ${getChainName(chosenChain)}`}
                pools={poolsByChain}
                visible
              />
            );
          } else {
            return {
              [PoolsGroup.Upcoming]: (
                <Group
                  title="Upcoming IDOs"
                  noPagination
                  pools={groups.upcoming}
                  onPoolChange={updatePool}
                  teasers={teaserPools}
                  visible
                />
              ),
              [PoolsGroup.Contributed]: (
                <PoolsTable
                  title="Contributed"
                  pools={groups.contributed}
                  onPoolChange={updatePool}
                  visible
                  showNextClaim={true}
                  contributionsByPool={contributionsByPool}>
                  <ContributionStats contributions={contributions} />
                </PoolsTable>
              ),
              [PoolsGroup.Ended]: (
                <PoolsTable
                  title="Ended IDOs"
                  pools={groups.ended}
                  onPoolChange={updatePool}
                  visible
                  isEnded
                />
              ),
              [PoolsGroup.EndedNft]: (
                <Group
                  title="Ended INOs"
                  pools={groups.endedNft}
                  onPoolChange={updatePool}
                  visible
                />
              ),
              [PoolsGroup.Refunded]: (
                <Group
                  title="Refunded"
                  pools={groups.refunded}
                  onPoolChange={updatePool}
                  visible>
                  Projects not following our{' '}
                  <a href="https://docs.trustpad.io/trustpad/safeguarded-launch-protocol">
                    Safeguarded Launch Protocol
                  </a>{' '}
                  are refunded.
                </Group>
              ),
            }[activeGroup];
          }
        })()}
      </Container>
    </section>
  );
}
