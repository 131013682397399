import { PoolConfig, useIsTokenListed } from '@trustpad/launchpad';
import { isNFTPool } from '@trustpad/launchpad/dist/pool/utils';

import { unknownTokenImg } from 'assets';
import { DBUserSaleContribution } from 'lib/types';
import Link from 'next/link';
import Skeleton from 'react-loading-skeleton';

import { SocialLinks } from '~common/components/SocialLinks';
import useScreenWidth from '~features/landing/TabPools/useScreenWidth';
import {
  usePool,
  useSaleBaseAllocation,
  useSaleTimeline,
} from '~features/pools';
import { getHardCap, getPoolLinkPath } from '~features/pools/utils';
import { getATHX } from '~features/price';
import { ProgressBar } from '~features/ui';
import { fNum, toEthN } from '~utils';

function getTokenSymbol(pool: PoolConfig) {
  const { token, fundToken } = pool;
  if (isNFTPool(pool)) {
    return 'INO';
  }
  if (!token.symbol) {
    return 'TBA';
  }
  if (fundToken.symbol !== 'BUSD') {
    return `$${token.symbol} / ${fundToken.symbol}`;
  }
  return `$${token.symbol}`;
}

const getXClassName = (v: number) =>
  v
    ? v >= 1
      ? 'bg-opacity-40 text-green-500'
      : 'bg-opacity-40 text-red-500'
    : '';
export function PoolRow({
  contribution,
  price,
  isEnded,
}: {
  price?: { price: number; athPrice: number };
  contribution?: DBUserSaleContribution;
  isEnded?: boolean;
}) {
  const { pool } = usePool();
  const { sale, token, fundToken } = pool;
  const { end } = useSaleTimeline();
  const { baseCurrencyAllocation } = useSaleBaseAllocation();
  const { listingDate } = useIsTokenListed();
  const windowWidth = useScreenWidth();
  const athX = price ? getATHX({ token, price: price.athPrice, sale }) : 0;
  const curX = price ? getATHX({ token, price: price.price, sale }) : 0;
  const filledPercent =
    (toEthN(sale.tokensSold) / toEthN(sale.tokensForSale)) * 100;

  return (
    <tr className="text-center text-white text-xs sm:text-base bg-dark2 hover:bg-mainDark/40 border-b border-mainDark relative">
      <td className="py-4 relative">
        <Link
          href={getPoolLinkPath(pool)}
          className="absolute top-0 left-0 h-full w-full z-10"
        />
        <div className="flex gap-3 items-center">
          {windowWidth > 767 && (
            <div className="h-12 w-12 shrink-0 overflow-hidden rounded-full border-2 border-mainDark">
              <img
                className="w-full"
                src={token.imageSrc || unknownTokenImg.src}
                alt={token.name}
              />
            </div>
          )}
          <div className="text-left">
            <h2 className="text-xs sm:text-lg font-semibold font-kgqj text-white lg:leading-none">
              {token.name}
            </h2>
            <p className="text-secondary leading-none">
              {getTokenSymbol(pool)}
            </p>
          </div>
        </div>
      </td>

      {isEnded ? (
        <></>
      ) : (
        <>
          <td className="py-4">
            <div className={`${windowWidth > 376 ? 'w-auto' : 'w-[50px]'}`}>
              {listingDate.isValid()
                ? listingDate.format('MMM DD, YYYY')
                : end
                ? `to be listed`
                : ''}
            </div>
          </td>
          {contribution ? (
            <td className="py-4 text-left">
              {price?.price && price?.price > 0 ? (
                `$${fNum(price?.price, 4)}`
              ) : (
                <Skeleton width={50} />
              )}

              {curX > 0 && (
                <span className={`ml-1 py-4 small ${getXClassName(curX)}`}>
                  {curX.toFixed(2) + 'x'}
                </span>
              )}
            </td>
          ) : (
            <td className={`py-4 ${getXClassName(athX)}`}>
              {athX ? athX.toFixed(2) + 'x' : 'TBA'}
            </td>
          )}

          {contribution ? (
            <td className="py-4">${fNum(contribution.contributedTotal, 2)}</td>
          ) : (
            <td className="py-4">
              {fNum(baseCurrencyAllocation, 2)
                ? '$' + fNum(baseCurrencyAllocation, 2)
                : 'TBA'}
            </td>
          )}
        </>
      )}

      {windowWidth > 991 && (
        <td className="py-4">
          <SocialLinks size="text-xl" social={pool.social} tableStyles />
        </td>
      )}

      <td className="py-4">
        {getHardCap(sale, fundToken).replace(' USD', '').replace(',', ' ')}
      </td>

      {isEnded && (
        <td className="py-4 w-64 px-4">
          <ProgressBar
            progress={filledPercent}
            withLabel
            height="1rem"
            className="bg-dark-grayMix"
          />
        </td>
      )}
    </tr>
  );
}
