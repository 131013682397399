import Link from 'next/link';
import React from 'react';
import { Button, Container } from 'react-bootstrap';

import { SectionTitle } from '~common/ui/SectionTitle';
import { tiers } from '~config/tiers';
import { TierCard } from '~features/tiers';
import { BlackLevel } from '~features/tiers/components/BlackLevel';

export function TabLevels() {
  return (
    <section className="py-12 bg-gray-600 bg-opacity-5">
      <Container>
        <div className="flex justify-center items-center gap-6 mb-12">
          <SectionTitle className="mb-0">
            <strong>TrustPAD</strong> Levels
          </SectionTitle>

          <div>
            <Link href="/levels" passHref legacyBehavior>
              <Button
                title="Stake"
                variant="main"
                className="text-lg"
                size="lg">
                Stake
              </Button>
            </Link>
          </div>
        </div>

        <SectionTitle className="mb-3">Guaranteed Levels</SectionTitle>

        <div className="flex flex-wrap md:-mx-6">
          {Object.values(tiers)
            .filter((t) => t.id !== 'none' && !t.random)
            .map((tier) =>
              tier.vip ? (
                <div key={tier.id} className="p-3 py-6 md:w-1/3 mx-auto w-full">
                  <BlackLevel tier={tier} />
                </div>
              ) : (
                <div key={tier.id} className="p-3 py-6 md:w-1/3 w-full">
                  <TierCard tier={tier} />
                </div>
              ),
            )}
        </div>
        <div className="mx-auto mt-6 py-6 text-center">
          <Link href="/levels" passHref legacyBehavior>
            <Button title="Stake" variant="main" className="text-xl" size="lg">
              Stake
            </Button>
          </Link>
        </div>
      </Container>
    </section>
  );
}
