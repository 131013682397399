import Link from 'next/link';
import { useEffect, useState } from 'react';
import { Container, Nav, Tab } from 'react-bootstrap';

import { DefaultNavbar } from '~common/BaseLayout';
import { Hero } from '~features/landing/Hero';
import { LandingLayout } from '~features/landing/LandingLayout';
import { TabAbout } from '~features/landing/TabAbout/TabAbout';
import { TabLevels } from '~features/landing/TabLevels';
import { TabPools } from '~features/landing/TabPools/TabPools';
import { TokenStats } from '~features/landing/TokenStats';
import { TopProjects } from '~features/landing/TopProjects/TopProjects';

import { poolsConfigById } from '~config/poolsConfig';
import { PoolConfig } from '~features/pools/types';

export default function Home() {
  const [activeTab, setActiveTab] = useState('pools');
  const [pools, setPools] =
    useState<Record<string, PoolConfig>>(poolsConfigById);
  const updatePool = (pool) => {
    // When pool is loaded from contract, it might become "finished"
    setPools((oldPools) => {
      if (oldPools[pool.id].sale.status === pool.sale.status) {
        return oldPools;
      }
      return { ...pools, [pool.id]: pool };
    });
  };

  function activateTab(tab: string) {
    setActiveTab(tab);
    location.hash = `#${tab}`;
  }

  useEffect(() => {
    if (process.browser) {
      const tab = window.location.hash.replace('#', '');
      if (tab) {
        setActiveTab(tab);
      }
    }
  }, []);

  return (
    <LandingLayout
      navAndHero={
        <>
          <DefaultNavbar onNavClick={activateTab} staking={false} />
          <div className="block mx-3 xll:py-20 xll:flex">
            <div className="block xll:mx-auto">
              <Hero />
            </div>
          </div>
        </>
      }>
      <Tab.Container
        defaultActiveKey="pools"
        onSelect={activateTab}
        activeKey={activeTab}>
        <div className="bg-dark2">
          <Container>
            <Nav className="pages-tabs justify-center font-semibold">
              <Nav.Item>
                <Nav.Link eventKey="about">About</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="pools">Pools 🔥</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="levels">Levels</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Link href="/levels" passHref className="nav-link">
                  Staking
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link href="/staking" passHref className="nav-link">
                  Farm 🌾
                </Link>
              </Nav.Item>
              {false && (
                <Nav.Item>
                  <Nav.Link eventKey="tokenomics">Tokenomics</Nav.Link>
                </Nav.Item>
              )}
              <Nav.Item>
                <Link
                  href="https://snapshot.org/#/trustpad.io"
                  passHref
                  className="nav-link"
                  target="_blank">
                  DAO
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link
                  href="https://docs.trustpad.io/trustpad/how-to-participate-in-an-ido"
                  passHref
                  className="nav-link">
                  Docs
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link href="/levels#faq" passHref className="nav-link">
                  FAQ
                </Link>
              </Nav.Item>
            </Nav>
          </Container>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="about">
            <TabAbout />
          </Tab.Pane>
          <Tab.Pane eventKey="pools">
            <div id="pools">
              <TopProjects />

              <TabPools pools={Object.values(pools)} updatePool={updatePool} />
            </div>
          </Tab.Pane>
          <Tab.Pane eventKey="levels">
            <div id="levels">
              <TabLevels />
            </div>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </LandingLayout>
  );
}
