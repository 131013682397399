import { SaleStatus } from '@trustpad/launchpad';
import { isNFTPool } from '@trustpad/launchpad/dist/pool/utils';

import sortBy from 'lodash/sortBy';

import { PoolConfig } from '~features/pools';
import { toEthN } from '~utils';

export enum PoolsGroup {
  Upcoming = 'upcoming',
  Ended = 'ended',
  EndedNft = 'ended-nft',
  Contributed = 'contributed',
  Refunded = 'refunded',
}

export const getGroupedPools = (
  pools: PoolConfig[],
  contributedPoolIds: string[],
) => {
  const ended = pools
    .filter(
      (p) => [SaleStatus.finished].includes(p.sale.status as SaleStatus),
      // !p.sale.refunded,
    )
    .sort((a, b) => (a.sale.startDate > b.sale.startDate ? -1 : 1));
  const refunded = pools.filter((p) => p.sale.refunded);
  const endedIds = ended.map((p) => p.id).concat(refunded.map((p) => p.id));
  const upcomingSorted = sortBy(
    pools.filter((p) => !endedIds.includes(p.id)),
    [
      (p) => (p.sale.startDate ? -1 : 1),
      (p) => (p.sale.startDate ? new Date(p.sale.startDate).getTime() : 0),
      (p) => p.id,
    ],
  );
  const contributed = sortBy(
    ended.filter((p) => contributedPoolIds.includes(p.id)),
    (p) => (p.sale.refunded ? 1 : -1),
    (p) => (p.sale.startDate ? -new Date(p.sale.startDate).getTime() : 0),
  );

  return {
    upcoming: upcomingSorted,
    ended: ended.filter((p) => !isNFTPool(p)),
    // There's a bug where tokensForSale is in wei, but tokensSold is in int
    endedNft: ended.filter(
      (p) =>
        isNFTPool(p) &&
        p.sale.tokensSold.toNumber() === toEthN(p.sale.tokensForSale),
    ),
    contributed,
    refunded,
  };
};
