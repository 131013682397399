import { FaSearch } from 'react-icons/fa';

export function SearchInput({
  value,
  onChange,
}: {
  value;
  onChange: (query: string) => void;
}) {
  return (
    <label
      className={`flex items-center w-72 text-left space-x-3 px-4 h-12
    focus-within:outline-none  border-mainDark border-2 focus-within:border-main focus-within:brand-shadow rounded-lg 
    bg-dark2 text-white highlight-white/5 hover:bg-dark3 mb-0 xll:ml-6`}>
      <FaSearch className="inline" />

      <input
        type="search"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder="search by name, token"
        className="w-full bg-transparent outline-none ring-0"
      />
    </label>
  );
}
