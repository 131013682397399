import { SaleStatus } from '@trustpad/launchpad';

import { teaserPools } from 'pools';

import {
  getGroupedPools,
  PoolsGroup,
} from '~features/landing/TabPools/getGroupedPools';

export function GroupButtons({
  groups,
  activeGroup,
  showContributed,
  showRefunded = true,
  onClick,
}: {
  groups: ReturnType<typeof getGroupedPools>;
  activeGroup: PoolsGroup | null;
  showContributed: boolean;
  showRefunded?: boolean;
  onClick: (
    group: PoolsGroup,
  ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  return (
    <>
      <GroupButton
        active={activeGroup === PoolsGroup.Upcoming}
        roundedBorderStyle="xll:rounded-l-lg xll:rounded-r-none"
        onClick={onClick(PoolsGroup.Upcoming)}>
        upcoming
        {groups.upcoming.some((p) => p.sale.status === SaleStatus.open) &&
          '🔥'}{' '}
        [{groups.upcoming.length + teaserPools.length}]
      </GroupButton>
      {showContributed && (
        <GroupButton
          active={activeGroup === PoolsGroup.Contributed}
          roundedBorderStyle={
            showRefunded
              ? 'xll:rounded-none'
              : 'xll:rounded-r-lg xll:rounded-l-none'
          }
          leftMargin
          onClick={onClick(PoolsGroup.Contributed)}>
          contributed [{groups.contributed.length}]
        </GroupButton>
      )}

      <GroupButton
        active={activeGroup === PoolsGroup.Ended}
        roundedBorderStyle="xll:rounded-none"
        leftMargin
        onClick={onClick(PoolsGroup.Ended)}>
        ended [{groups.ended.length}]
      </GroupButton>
      <GroupButton
        active={activeGroup === PoolsGroup.EndedNft}
        roundedBorderStyle={
          showContributed || showRefunded
            ? 'xll:rounded-none'
            : 'xll:rounded-r-lg xll:rounded-l-none'
        }
        leftMargin
        onClick={onClick(PoolsGroup.EndedNft)}>
        ended NFT [{groups.endedNft.length}]
      </GroupButton>

      {showRefunded && (
        <GroupButton
          active={activeGroup === PoolsGroup.Refunded}
          roundedBorderStyle="xll:rounded-r-lg xll:rounded-l-none"
          leftMargin
          onClick={onClick(PoolsGroup.Refunded)}>
          refunded [{groups.refunded.length}]
        </GroupButton>
      )}
    </>
  );
}

function GroupButton({
  active,
  roundedBorderStyle,
  leftMargin,
  onClick,
  children,
}: {
  active: boolean;
  roundedBorderStyle: string;
  leftMargin?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children: React.ReactNode;
}) {
  return (
    <button
      className={`inline-block align-middle px-4 py-3 border-2 rounded-lg active:border-main active:brand-shadow text-white text-center transform transition bg-dark2 ring-none select-none focus:outline-0 ${
        active
          ? 'ring-mainDark brand-bg brand-shadow z-10 border-main'
          : 'hover:bg-opacity-20 hover:bg-gray-800 hover:-translate-y-1 border-mainDark bg-none shadow-none'
      } ${roundedBorderStyle} ${leftMargin ? 'lg:-ml-[2px]' : ''}`}
      onClick={onClick}>
      {children}
    </button>
  );
}
