import { normalizeAddress } from '@trustpad/launchpad/dist/shared/utils';
import axios from 'axios';
import uniq from 'lodash/uniq';
import { useEffect, useState } from 'react';

export type TokenPrice = {
  address: string;
  poolId: string;
  coinGeckoId?: string;
  coinMarketCapId?: string;
  price: number;
  athPrice: number;
};

const emptyPrice = { price: 0, athPrice: 0, poolId: undefined };
const cache = new Map<string, TokenPrice>();
let idsToFetch = new Set();
let batchPromise;

const fetchBatch = () => {
  return axios
    .get(`/api/token/price?addresses=${Array.from(idsToFetch).join()}`)
    .catch(() => undefined);
};

const fetchPrice = (address: string) => {
  if (!address) {
    return Promise.resolve(emptyPrice);
  }
  if (cache.has(address)) {
    return Promise.resolve(cache.get(address));
  }

  idsToFetch.add(address);

  return new Promise((resolve) => {
    if (!batchPromise) {
      batchPromise = new Promise((resolve) => {
        setTimeout(() => {
          if (idsToFetch.size > 0) {
            fetchBatch().then(resolve);
          }

          batchPromise = undefined;
          idsToFetch.clear();
        }, 200);
      });
    }
    batchPromise.then((res) => {
      const item = res?.data.find(
        (x) => normalizeAddress(x.address) === normalizeAddress(address),
      ) as TokenPrice;
      if (item) {
        cache.set(address, item);
      } 
      resolve(item);
    });
  });
};

export function useServerCachedTokenPrice({
  tokenAddresses,
  realTime,
}: {
  tokenAddresses: string[];
  // Real-time will use the price directly from CoinGecko API
  realTime?: boolean;
}) {
  const [prices, setPrices] = useState<Record<string, TokenPrice>>({});
  const [isLoading, setIsLoading] = useState(tokenAddresses.length > 0);

  useEffect(() => {
    if (tokenAddresses.length) {
      Promise.all(uniq(tokenAddresses).filter(Boolean).map(fetchPrice))
        .then((prices) => {
          setPrices(
            // @ts-ignore
            prices.filter(Boolean).reduce((acc, item) => {
              // @ts-ignore
              acc[normalizeAddress(item.address)] = item;
              return acc;
            }, {}),
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [tokenAddresses?.join()]);

  return {
    prices,
    isLoading,
  };
}
