import { SaleStatus } from '@trustpad/launchpad';
import { normalizeAddress } from '@trustpad/launchpad/dist/shared/utils';

import { DBUserSaleContribution } from 'lib/types';
import React, { useMemo, useRef, useState } from 'react';

import { PoolRow } from '~features/landing/TabPools/PoolRow';
import sortPools from '~features/landing/TabPools/sortPools';
import useScreenWidth from '~features/landing/TabPools/useScreenWidth';
import {
  PoolConfig,
  PoolProvider,
  UserSaleTierProvider,
} from '~features/pools';
import { useServerCachedTokenPrice } from '~features/price';
import { ProgressBar } from '~features/ui';
import { Chain } from '~types';

import { Pagination } from './Pagination';
import SortHeader from './SortHeader';
import { usePagination } from './usePagination';

export function PoolsTable({
  title,
  visible,
  pools,
  teasers,
  bottom,
  noPagination,
  isEnded,
  contributionsByPool,
  onPoolChange,
  children,
}: {
  title: string;
  visible: boolean;
  pools: PoolConfig[];
  teasers?: Chain[];
  bottom?: React.ReactNode;
  noPagination?: boolean;
  showNextClaim?: boolean;
  isEnded?: boolean;
  contributionsByPool?: Record<string, DBUserSaleContribution>;
  onPoolChange?: (pool: PoolConfig) => void;
  children?: React.ReactNode;
}) {
  const ref = useRef<HTMLDivElement>();

  const [sortAsc, setSortAsc] = useState(false);
  const [sortField, setSortField] = useState<'totalRaise' | 'listed'>('listed');
  const sortedPools = useMemo(
    () => sortPools(pools, sortField, sortAsc),
    [sortAsc, pools, sortField],
  );

  const { pageItems, ...pagination } = usePagination<PoolConfig>({
    allItems: sortedPools,
    perPage: noPagination ? pools.length : 21,
    onPageChange: () => {
      ref.current.scrollIntoView(true);
    },
  });

  const { prices, isLoading } = useServerCachedTokenPrice({
    tokenAddresses: pageItems.map((pool) => pool.token.address),
  });

  const windowWidth = useScreenWidth();

  const heading = <h2 className="text-4xl">{title}</h2>;

  function handleSortClick(headingId: 'totalRaise' | 'listed') {
    if (headingId === sortField) {
      setSortAsc(!sortAsc);
    } else {
      setSortAsc(false);
      setSortField(headingId);
    }
  }

  if (!pools.length && !teasers?.length) {
    return (
      <section className={`mb-12 space-y-3 ${visible ? '' : 'hidden'}`}>
        {heading}

        <p className="text-center text-white text-xl pt-12">Nothing here...</p>
      </section>
    );
  }

  return (
    <section className={`mb-12 space-y-3 ${visible ? '' : 'hidden'}`}>
      {heading}

      {children}

      <div ref={ref}>
        <table className="w-full border-collapse">
          <thead>
            <tr className="border-b-[3px] border-mainDark text-center text-secondary tracking-wider text-xs sm:text-sm align-bottom">
              <th className="text-left py-4">Project</th>

              {isEnded ? (
                <></>
              ) : (
                <>
                  <SortHeader
                    sortField={sortField}
                    sortAsc={sortAsc}
                    id="listed"
                    onClick={handleSortClick}>
                    Listed
                  </SortHeader>
                  {contributionsByPool ? (
                    <th className="py-4 text-left">Current Price</th>
                  ) : (
                    <th className="py-4" id="ath">
                      ATH{' '}
                    </th>
                  )}
                  {contributionsByPool ? (
                    <th className="py-4">Contributed</th>
                  ) : (
                    <th id="base-allocation" className="py-4">
                      {windowWidth > 991 ? 'Base Alloc' : 'Base Alloc'}{' '}
                    </th>
                  )}
                </>
              )}
              {windowWidth > 991 && <th className="py-4">Social</th>}
              <SortHeader
                sortField={sortField}
                sortAsc={sortAsc}
                id="totalRaise"
                onClick={handleSortClick}>
                Total Raised
              </SortHeader>
              {isEnded && <th>Filled</th>}
            </tr>
          </thead>
          <tbody>
            {pageItems.map((pool) => (
              <PoolProvider key={pool.id} config={pool} onChange={onPoolChange}>
                <UserSaleTierProvider
                  doFetch={pool.sale.status !== SaleStatus.finished}>
                  <PoolRow
                    key={pool.id}
                    contribution={contributionsByPool?.[pool.id]}
                    isEnded={isEnded}
                    price={
                      isLoading
                        ? null
                        : prices[normalizeAddress(pool.token.address)]
                    }
                  />
                </UserSaleTierProvider>
              </PoolProvider>
            ))}
          </tbody>
        </table>
      </div>

      <div className="flex items-center mx-auto mt-4">
        <div className="flex-1">
          {pagination.totalPages > 1 && <Pagination {...pagination} />}
        </div>

        {bottom && <div className="ml-auto">{bottom}</div>}
      </div>
    </section>
  );
}
