export { useServerCachedTokenPrice } from './useServerCachedTokenPrice';

export function getATHX({ token, price, sale }) {
  if (token.athXManual) {
    return token.athXManual;
  } else if (price && sale.rate) {
    return price / (1 / sale.rate);
  }

  return undefined;
}
