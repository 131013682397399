import { PoolConfig } from '@trustpad/launchpad';

import { useState } from 'react';

export function useChainFilter(pools: PoolConfig[]) {
  const [chosenChain, setChosenChain] = useState('');

  const poolsByChain = pools.filter((p) => {
    if (p.token.chain === chosenChain) {
      return p.token.chain;
    }
  });

  return { chosenChain, setChosenChain, poolsByChain };
}
