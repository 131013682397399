import { SkeletonTheme } from 'react-loading-skeleton';

import { ErrorBoundary } from '~common/ErrorBoundary';
import { useTokenStats } from '~common/hooks/useTokenStats';
import TokenStatsCard from '~features/landing/TokenStatsCard';

function formatShort(num, digits = 2) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0';
}

export function TokenStatsNew() {
  const { projectsFdv, projectsVolume30d, raisedCapital, launchedProjects } =
    useTokenStats();

  return (
    <ErrorBoundary>
      <SkeletonTheme baseColor="#202020" highlightColor="#FFFFFF">
        <div className="grid grid-cols-2 gap-6 lg:grid-cols-4 mt-12 xll:mt-9 mb-6 xll:mb-0 xll:ml-1 ">
          <TokenStatsCard label="RAISED CAPITAL">
            ${raisedCapital ? formatShort(raisedCapital) : raisedCapital}
          </TokenStatsCard>
          <TokenStatsCard label="LAUNCHED PROJECTS">
            {launchedProjects}+
          </TokenStatsCard>
          <TokenStatsCard label="ATH AVG ROI">68.82x</TokenStatsCard>
          <TokenStatsCard label="PROJECTS' FDV MKT CAP">
            ${projectsFdv ? formatShort(projectsFdv) : projectsFdv}
          </TokenStatsCard>
        </div>
      </SkeletonTheme>
    </ErrorBoundary>
  );
}
