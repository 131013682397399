import { PoolConfig } from '@trustpad/launchpad';

import { babylons } from 'pools/finished/babylons';
import { bithotel } from 'pools/finished/bithotel';
import { blokpad } from 'pools/finished/blokpad';
import { bloktopia } from 'pools/finished/bloktopia';
import { bullieverse } from 'pools/finished/bullieverse';
import { bullperks } from 'pools/finished/bullperks';
import { continuum } from 'pools/finished/continuum';
import { gensokishi } from 'pools/finished/gensokishi';
import { husl } from 'pools/finished/husl';
import { netvrk } from 'pools/finished/netvrk/netvrk';
import { nftpad } from 'pools/finished/nftpad';
import { polkaFantasy } from 'pools/finished/polkafantasy/polkaFantasy';
import { qan } from 'pools/finished/qan';
import { sidus } from 'pools/finished/sidus';
import { snook } from 'pools/finished/snook';
import { solchicks } from 'pools/finished/solchicks';
import { syncity } from 'pools/finished/syncity/syncity';
import senateImg from 'pools/images/senate-token.png';
import sidusImg from 'pools/images/sidus-token.png';

import { toEthN, tokensToCurrency } from '~utils';

export type Project = {
  img: string;
  name: string;
  address: string;
  CGId: string;
  initPrice: number;
  baseUsdAlloc: number; // 1x
  url: string;
  ath?: string;
};

const toProject = (
  pool: PoolConfig,
): Pick<
  Project,
  'img' | 'name' | 'baseUsdAlloc' | 'CGId' | 'initPrice' | 'url' | 'address'
> => {
  return {
    img: pool.token.imageSrc,
    name: pool.token.name,
    address: pool.token.address,
    baseUsdAlloc: toEthN(
      tokensToCurrency({
        rate: pool.sale.rate,
        value: pool.sale.baseAllocation,
        decimals: 18,
      }),
    ),
    CGId: pool.token.coingeckoId,
    initPrice: 1 / pool.sale.rate,
    url: `/pool/${pool.id}`,
  };
};

export const projects: Project[] = [
  toProject(polkaFantasy),
  toProject(snook),
  toProject(bithotel),
  toProject(bullperks),
  toProject(bloktopia),
  toProject(blokpad),
  toProject(gensokishi),
  {
    ...toProject(sidus),
    name: 'SIDUS',
    img: sidusImg.src,
    ath: '107.35',
  },
  { ...toProject(sidus), name: 'SENATE', img: senateImg.src, ath: '32.50' },
  {
    ...toProject(nftpad),
    ath: '17.7',
  },
  toProject(babylons),
  toProject(syncity),
  toProject(husl),
  toProject(qan),
  toProject(solchicks),
  toProject(netvrk),
  toProject(bullieverse),
  toProject(continuum),
];
