import React from 'react';
import { Container } from 'react-bootstrap';

import { SectionTitle } from '~common/ui/SectionTitle';
import styles from '~features/landing/Landing.module.css';

import { chainlink, genesis } from './partners';

function PartnerItem({ src, width = '12rem', alt }) {
  return (
    <div className="mx-12 py-3">
      <img
        className={`${styles.whiteImg}`}
        src={src?.src || src}
        // width={width}
        alt={alt}
        style={{ maxWidth: width }}
      />
    </div>
  );
}

export function PartnersSection() {
  return (
    <section className="text-center py-12 pb-16">
      <Container>
        <SectionTitle>Partners</SectionTitle>

        <div className="flex flex-wrap justify-center items-center">
          <PartnerItem alt="Chainlink" src={chainlink} width="14rem" />
        </div>
      </Container>
    </section>
  );
}
