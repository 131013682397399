import { useWeb3Provider } from '@trustpad/launchpad';

import axios from 'axios';
import { useEffect, useState } from 'react';

export function useTokenStats() {
  const { web3 } = useWeb3Provider();
  const [data, setData] = useState<{
    tvl: number;
    price: number;
    circulatingSupply: number;
    totalSupply: number;
    projectsFdv: number;
    projectsVolume30d: number;
    stakersCount: number;
    stakedAmount: number;
    raisedCapital: number;
    launchedProjects: number;
    launchedSales: number;
    refundedProjects: number;
    marketCap: number;
    fdvMarketCap: number;
    athRoi: number;
    supplyStakedPercentage: number;
  }>({
    tvl: 0,
    price: 0,
    circulatingSupply: 0,
    totalSupply: 0,
    projectsFdv: 0,
    projectsVolume30d: 0,
    stakersCount: 0,
    stakedAmount: 0,
    raisedCapital: 0,
    launchedProjects: 0,
    launchedSales: 0,
    refundedProjects: 0,
    marketCap: 0,
    fdvMarketCap: 0,
    athRoi: 0,
    supplyStakedPercentage: 0,
  });

  useEffect(() => {
    if (!web3) return;

    axios
      .get<{
        tvl: number;
        price: number;
        circulatingSupply: number;
        totalSupply: number;
        projectsFdv: number;
        projectsVolume30d: number;
        stakersCount: number;
        stakedAmount: number;
        raisedCapital: number;
        launchedProjects: number;
        launchedSales: number;
        refundedProjects: number;
        marketCap: number;
        fdvMarketCap: number;
        athRoi: number;
        supplyStakedPercentage: number;
      }>('/api/stats')
      .then(({ data }) => {
        if (data) {
          setData({
            tvl: data.tvl,
            price: data.price,
            circulatingSupply: data.circulatingSupply,
            totalSupply: data.totalSupply,
            projectsFdv: data.projectsFdv,
            projectsVolume30d: data.projectsVolume30d,
            stakersCount: data.stakersCount,
            stakedAmount: data.stakedAmount,
            raisedCapital: data.raisedCapital,
            launchedProjects: data.launchedProjects,
            launchedSales: data.launchedSales,
            refundedProjects: data.refundedProjects,
            marketCap: data.marketCap,
            fdvMarketCap: data.fdvMarketCap,
            athRoi: data.athRoi,
            supplyStakedPercentage: data.supplyStakedPercentage,
          });
        }
      });
  }, [web3]);

  return data;
}
