import { SaleStatus } from '@trustpad/launchpad';

import React, { useRef } from 'react';
import { Col, Row } from 'react-bootstrap';

import {
  PoolCard,
  PoolConfig,
  PoolProvider,
  UserSaleTierProvider,
} from '~features/pools';
import { PoolCardTeaser } from '~features/pools/components/PoolCardTeaser';
import { Chain } from '~types';

import { Pagination } from './Pagination';
import { usePagination } from './usePagination';

export function Group({
  title,
  visible,
  pools,
  teasers,
  bottom,
  noPagination,
  showNextClaim,
  onPoolChange,
  children,
}: {
  title: string;
  visible: boolean;
  pools: PoolConfig[];
  teasers?: Chain[];
  bottom?: React.ReactNode;
  noPagination?: boolean;
  showNextClaim?: boolean;
  onPoolChange?: (pool) => void;
  children?: React.ReactNode;
}) {
  const ref = useRef<HTMLDivElement>();
  const { pageItems, ...pagination } = usePagination<PoolConfig>({
    allItems: pools,
    perPage: noPagination ? pools.length : 21,
    onPageChange: () => {
      ref.current.scrollIntoView(true);
    },
  });

  const heading = <h2 className="text-4xl">{title}</h2>;

  if (!pools.length && !teasers?.length) {
    return (
      <section className={`mb-12 space-y-3 ${visible ? '' : 'hidden'}`}>
        {heading}

        <p className="text-center text-white text-xl pt-12">Nothing here...</p>
      </section>
    );
  }

  return (
    <section className={`mb-12 space-y-3 ${visible ? '' : 'hidden'}`}>
      {heading}

      {children}

      <Row ref={ref}>
        {pageItems.map((pool) => (
          <Col md={6} lg={4} key={pool.id} className="py-6">
            <PoolProvider config={pool} onChange={onPoolChange}>
              <UserSaleTierProvider
                doFetch={pool.sale.status !== SaleStatus.finished}>
                <PoolCard key={pool.id} showNextClaim={showNextClaim} />
              </UserSaleTierProvider>
            </PoolProvider>
          </Col>
        ))}

        {teasers?.map((teaser, idx) => (
          <Col md={6} lg={4} key={`${idx}-${teaser}`} className="py-6">
            <PoolCardTeaser network={teaser} />
          </Col>
        ))}
      </Row>

      <div className="flex items-center mx-auto mt-4">
        <div className="flex-1">
          {pagination.totalPages > 1 && <Pagination {...pagination} />}
        </div>

        {bottom && <div className="ml-auto">{bottom}</div>}
      </div>
    </section>
  );
}
