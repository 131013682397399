import { normalizeAddress } from '@trustpad/launchpad/dist/shared/utils';
import Link from 'next/link';
import React from 'react';
import { useServerCachedTokenPrice } from '~features/price';
import { TokenPrice } from '~features/price/useServerCachedTokenPrice';
import { fNum } from '~utils';
import { Project, projects } from './projects';

import styles from './TopProjects.module.css';

const projectsDouble = [...projects, ...projects];

export function TopProjects() {
  const { prices } = useServerCachedTokenPrice({
    tokenAddresses: projects.map((p) => p.address),
  });

  return (
    <section className="py-3 mb-3">
      <h3 className="uppercase text-center text-lg tracking-widest font-secondary mb-0">
        Featured
      </h3>

      <div className="relative h-28 bg-dark1 overflow-hidden">
        <div className={styles.container}>
          {projectsDouble.map((project, k) => (
            <Link
              href={project.url}
              key={project.name + k}
              className="no-underline text-current hover:text-current">
              <ProjectCard
                project={project}
                prices={prices[normalizeAddress(project.address)]}
              />
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

function ProjectCard({
  project,
  prices,
}: {
  project: Project;
  prices: TokenPrice;
}) {
  return (
    <div
      className={`border border-gray-500 bg-dark3 border-opacity-20 inline-flex px-6 py-3 rounded-xl
           h-24 hover:bg-darkGray bg-opacity-50`}>
      <div className="flex justify-center items-center space-x-3">
        <div className="h-16 w-16 flex items-center justify-center overflow-hidden">
          <img className=" rounded" src={project.img} alt={project.name} />
        </div>
        {(prices || project.ath) && (
          <div className="">
            <p className="text leading-none">{project.name}</p>
            <p className="mt-1 leading-none text-xl">
              <span className="font-semibold brand-text">
                {project.ath ||
                  (prices.athPrice / project.initPrice).toFixed(2)}
                x
              </span>
            </p>
          </div>
        )}

        {false && prices && (
          <div className="border-l pl-3 border-gray-500 border-opacity-30">
            <p className="text-xs text-secondary">
              <span className="font-semibold text-white">
                ${fNum(project.baseUsdAlloc, 0)}
              </span>{' '}
              initial investment <br />
              Earned up to{' '}
              <span className="font-semibold text-white">
                ${fNum(project.baseUsdAlloc * prices.athPrice, 0)}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
