import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export function usePagination<T>({
  allItems,
  perPage = 15,
  onPageChange,
}: {
  allItems: T[];
  perPage?: number;
  onPageChange?: (newPage: number) => void;
}) {
  const router = useRouter();
  const urlQueryPage = parseInt(router.query['p'] as string, 10) || 0;
  const [page, setPage] = useState(urlQueryPage);

  const totalPages = Math.ceil(allItems.length / perPage);
  const pageItems = allItems.slice(page * perPage, (page + 1) * perPage);

  useEffect(() => {
    if (page !== urlQueryPage) {
      setPage(urlQueryPage);
    }
  }, [page, urlQueryPage]);

  return {
    page,
    totalPages,
    pageItems,
    goToPage: (page: number) => {
      const validPage = Math.min(Math.max(page, 0), totalPages);
      const url = { query: { ...router.query, p: page } };
      router.push(url, url, { shallow: true });
      setPage(validPage);
      onPageChange(validPage);
    },
  };
}
