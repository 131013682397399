import uniq from 'lodash/uniq';
import React from 'react';

const MAX_PAGES = 4;
const PAGES_AROUND = 2;

const getPagesSlice = (total: number, current: number) => {
  const pages = Array(total)
    .fill(0)
    .map((_, pageNumber) => pageNumber);

  return pages;

  // TODO: finish

  const startSlice = pages.slice(0, MAX_PAGES);
  if (current >= MAX_PAGES) {
    startSlice.push(-1);
  }

  const middleSlice = pages.slice(
    Math.max(0, current - PAGES_AROUND),
    current + PAGES_AROUND,
  );

  const endSlice =
    total - 2 - current < MAX_PAGES
      ? pages.slice(current + 1)
      : [-1, ...pages.slice(total - MAX_PAGES)];

  console.log(
    `total ${total}, current ${current}`,
    startSlice,
    middleSlice,
    endSlice,
  );

  return uniq([...startSlice, ...middleSlice, ...endSlice]);
};

export function Pagination({ totalPages, page, goToPage }) {
  const pages = getPagesSlice(totalPages, page);

  return (
    <div className="flex gap-3 items-center">
      {pages.map((pageNumber) => {
        if (pageNumber === -1) {
          return <span key={pageNumber}>...</span>;
        }

        return (
          <button
            key={pageNumber}
            onClick={() => goToPage(pageNumber)}
            className={`px-4 py-2 w-18 text-lg rounded-lg border border-pink/60 ${
              pageNumber === page ? 'bg-pink/90 border-0' : ''
            }`}>
            {pageNumber + 1}
          </button>
        );
      })}
    </div>
  );
}
