import { Container } from 'react-bootstrap';

import { SectionTitle } from '~common/ui/SectionTitle';
import styles from '~features/landing/Landing.module.css';

import {
  au21,
  blocksync,
  exntc,
  gd10,
  genblock,
  lupa,
  m6,
  newfield,
  sv,
  t3e,
  twin,
  x21,
} from './partners';

function PartnerItem({ src, width = '12rem', alt }) {
  return (
    <div className="flex">
      <img
        className={`${styles.whiteImg} m-auto p-6 md:p-3`}
        src={src?.src || src}
        // width={width}
        alt={alt}
        style={{ maxWidth: width }}
      />
    </div>
  );
}

export function BackedBySection() {
  return (
    <section className="text-center py-12 pb-16">
      <Container>
        <SectionTitle>Backed By</SectionTitle>

        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3">
          <PartnerItem alt="BlockSync Ventures" src={blocksync} width="13rem" />
          <PartnerItem alt="4SV" src={sv} width="11rem" />
          <PartnerItem alt="Twin Apex Capital" src={twin} width="10rem" />
          <PartnerItem alt="Lupa X Capital" src={lupa} width="18rem" />
          <PartnerItem alt="NewField Fund" src={newfield} width="18rem" />

          {/*<PartnerItem alt="BuildHodl" src={buildhodl} width="13rem" />*/}
          <PartnerItem alt="X21" src={x21} width="7.5rem" />
          <PartnerItem alt="Momentum 6" src={m6} width="10rem" />
          <PartnerItem alt="GD 10" src={gd10} width="13rem" />
          {/*<PartnerItem alt="Lotus Capital" src={lotus} width="12rem" />*/}
          {/*<PartnerItem alt="Wealth Union" src={wealth} width="20rem" />*/}
          <PartnerItem alt="Genblock Capital" src={genblock} width="12rem" />
          <PartnerItem
            alt="T3E (There Exists Ventures)"
            src={t3e}
            width="17rem"
          />
          <PartnerItem alt="AU21 Capital" src={au21} width="19rem" />

          <PartnerItem alt="exNetwork Capital" src={exntc} width="9rem" />
        </div>
      </Container>
    </section>
  );
}
