import { HeroChainIcon } from 'src/features/landing/HeroChainIcon';
import { avaxImg, bnbImg, polyImg, solImg } from 'src/features/landing/icons';
import {
  arbitrumImg,
  ethImg,
  fantomImg,
} from 'src/features/pools/components/PoolCard/images';

export function HeroChainIcons() {
  return (
    <div className="flex flex-col items-center mb-0 sm:mb-12 xll:mt-9 xll:mb-0 xll:ml-1">
      <p className="text-sm text-gray-400 text-opacity-80 mb-6">
        Supported blockchains
      </p>
      <div className="flex flex-wrap justify-center">
        <HeroChainIcon
          style={{ padding: 10 }}
          src={ethImg.src}
          alt="Ethereum"
        />
        <HeroChainIcon
          style={{ padding: 0 }}
          src={bnbImg.src}
          alt="BNB Chain"
        />
        <HeroChainIcon style={{ padding: 1 }} src={solImg.src} alt="Solana" />
        <HeroChainIcon
          style={{ padding: 7 }}
          src={arbitrumImg.src}
          alt="Arbitrum"
        />
        {/*<HeroChainIcon style={{ padding: 6 }} src={nearImg.src} alt="NEAR" />*/}
        <HeroChainIcon
          style={{ padding: 5 }}
          src={fantomImg.src}
          alt="Fantom"
        />
        <HeroChainIcon
          style={{ padding: 0 }}
          src={avaxImg.src}
          alt="Avalanche"
        />
        <HeroChainIcon style={{ padding: 2 }} src={polyImg.src} alt="Polygon" />
        {/*<HeroChainIcon style={{ padding: 8 }} src={aptosImg.src} alt="Aptos" />*/}
      </div>
    </div>
  );
}
