import { Menu } from '@headlessui/react';
import { PoolConfig } from '@trustpad/launchpad';

import { useMemo } from 'react';
import { FaCaretDown } from 'react-icons/fa';

import { ChainIcon } from '~features/pools/components/PoolCard/ChainIcon';

export default function ChainDropdownFilter({
  pools,
  chosenChain,
  setChosenChain,
  setQuery,
  getChainName,
}: {
  pools: PoolConfig[];
  chosenChain: string;
  setChosenChain: (chain: string) => void;
  setQuery: (query: string) => void;
  getChainName: (chain: string) => string;
}) {
  const poolsChains = useMemo(
    () => Array.from(new Set(pools.map((p) => p.token.chain))),
    [pools],
  );

  function handleChainChoice(chain: string) {
    setChosenChain(chain);
    setQuery('');
  }

  return (
    <Menu as="div" className="relative -ml-px block xll:ml-6">
      {({ open }) => (
        <>
          <Menu.Button
            as="button"
            className={`${
              open || chosenChain
                ? 'border-main brand-shadow'
                : 'border-mainDark'
            } ${
              chosenChain ? 'brand-bg' : 'bg-dark2 hover:bg-dark3'
            } active:border-main active:brand-shadow inline-flex items-center pl-4 pr-3 py-3 border-2 rounded-lg text-white text-center transform transition ring-none select-none focus:outline-none`}>
            {chosenChain ? (
              <ChainIcon chain={chosenChain} filterButton />
            ) : (
              'any chain'
            )}
            <FaCaretDown className="inline ml-1" />
          </Menu.Button>
          <Menu.Items className="absolute right-0 z-10 mt-2 origin-top-right py-2 border-2 rounded-lg border-main brand-shadow text-white bg-dark2 ring-none select-none focus:outline-none">
            <Menu.Item
              as="div"
              className="flex whitespace-nowrap px-4 py-2 w-full hover:bg-mainDark/60 border-b-2 border-mainDark"
              onClick={() => setChosenChain('')}>
              any chain
            </Menu.Item>
            {poolsChains.map((chain: string) => (
              <Menu.Item
                as="div"
                key={chain}
                className="flex whitespace-nowrap px-4 py-2 w-full hover:bg-mainDark/60"
                onClick={() => handleChainChoice(chain)}>
                <div>{getChainName(chain)}</div>
                <div className="flex content-center ml-2">
                  <ChainIcon chain={chain} />
                </div>
              </Menu.Item>
            ))}
          </Menu.Items>
        </>
      )}
    </Menu>
  );
}
