import { PoolConfig } from '@trustpad/launchpad';

import { useState } from 'react';

export function usePoolSearch(pools: PoolConfig[]) {
  const [query, setQuery] = useState('');
  const trimmed = query.trim();
  const matcher = new RegExp(trimmed.replace(/\s+/, ' '), 'i');
  const shouldFilter = trimmed && trimmed.length > 1;

  const foundPools = pools.filter((p) => {
    if (!shouldFilter) {
      return false;
    }
    const str = [p.token.name, p.token.symbol].join(',').toLowerCase();
    return str.match(matcher);
  });

  return { query, setQuery, foundPools, shouldFilter };
}
