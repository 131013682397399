import React from 'react';
import { BaseLayout, SmokeBg } from '~common/BaseLayout';

export function LandingLayout({
  navAndHero,
  children,
}: {
  navAndHero: React.ReactNode;
  children: React.ReactNode;
}) {
  return (
    <BaseLayout>
      <SmokeBg>{navAndHero}</SmokeBg>

      <main className="relative mx-auto">{children}</main>
    </BaseLayout>
  );
}
