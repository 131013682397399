import { PoolConfig } from '~features/pools';
import { getHardCap } from '~features/pools/utils';

export default function sortPools(
  pools: PoolConfig[],
  sortField: 'totalRaise' | 'listed',
  sortAsc: boolean,
) {
  function sortByTotalRaise(a: PoolConfig, b: PoolConfig) {
    if (getHardCap(a.sale, a.fundToken) === 'TBA') {
      return +1;
    }
    if (getHardCap(b.sale, b.fundToken) === 'TBA') {
      return -1;
    }
    return (
      (parseInt(getHardCap(a.sale, a.fundToken).replace(/\D/g, '')) -
        parseInt(getHardCap(b.sale, b.fundToken).replace(/\D/g, ''))) *
      (sortAsc ? 1 : -1)
    );
  }

  function sortByListed(a: PoolConfig, b: PoolConfig) {
    if (a.token.listingTime === '') {
      return +1;
    }
    if (b.token.listingTime === '') {
      return -1;
    }
    return (
      (new Date(a.token.listingTime).getTime() -
        new Date(b.token.listingTime).getTime()) *
      (sortAsc ? 1 : -1)
    );
  }

  const sorters = {
    totalRaise: sortByTotalRaise,
    listed: sortByListed,
  };
  return [...pools].sort(sorters[sortField]);
}
