import { Container } from 'react-bootstrap';

import { SectionTitle } from '~common/ui/SectionTitle';

import styles from '../Landing.module.css';
import { BackedBySection } from './BackedBySection';
import { PartnersSection } from './PartnersSection';
import aptosImg from './networks/aptos.svg';
import arbitrumImg from './networks/arbitrum.png';
import avalancheImg from './networks/avalanche.svg';
import bnbImg from './networks/bnb-chain.svg';
import ethImg from './networks/ethereum_logo.png';
import fantomImg from './networks/fantom.svg';
import nearImg from './networks/near.svg';
import polyImg from './networks/polygon_logo.png';
import solImg from './networks/solana_logo.svg';

export function TabAbout() {
  return (
    <>
      <FeaturesSection />
      <div className={styles.separator} />

      {/*<NFTSection />*/}

      <CrossChainSection />

      <PartnersSection />
      {/*<AdvisorsSection />*/}
      <BackedBySection />
    </>
  );
}

function FeaturesSection() {
  const Highlight = ({ icon, children }) => (
    <div className="w-full lg:w-1/4 flex flex-col items-center text-center">
      <div className="h-48 mx-auto flex items-center">
        <i className={`${icon} ${styles.hightlightIcon}`}></i>
      </div>
      <h3 className="my-2 mx-2 text-xl text-white">{children}</h3>
    </div>
  );

  return (
    <section className="text-center py-12">
      <Container>
        <SectionTitle>
          <strong>TrustPad</strong> Key Features
        </SectionTitle>

        <p className={styles.subtitle}>
          Launching hand-picked high-quality projects on the Blockchain
        </p>

        <div className="flex flex-col md:grid md:grid-cols-2 lg:flex lg:flex-row mt-12 2xl:px-16">
          <Highlight icon="las la-spa">Fair and Transparent</Highlight>
          <Highlight icon="las la-user-shield">Rug-Proof Mechanisms</Highlight>
          <Highlight icon="las la-list-alt">Guaranteed Allocations</Highlight>
          <Highlight icon="las la-gem">High-Quality Projects</Highlight>
        </div>
      </Container>
    </section>
  );
}

function NFTSection() {
  return (
    <section className="text-center py-12 pb-16">
      <Container>
        <SectionTitle>
          <strong>NFTPad</strong> -Launchpad for Blockchain Games.
        </SectionTitle>

        <p className={styles.subtitle}>
          The Ultimate Cross-Chain NFT Platform.{' '}
        </p>
        <p className={styles.subtitleText}>
          NFT Launchpad and Marketplace 🚀 GAMING | ART | DEFI
        </p>

        <p className={styles.subtitleText}>
          <a href="https://nftpad.fi">NFTPad.fi</a>
        </p>

        <i className={`las la-icons ${styles.nftIcon}`} />
      </Container>
    </section>
  );
}

function CrossChainSection() {
  return (
    <section className="text-center py-12 bg-dark2 w-full">
      <Container>
        <SectionTitle yellow>
          <strong>Cross-Chain</strong> Integration
        </SectionTitle>

        <div className="flex flex-col sm:flex-row sm:flex-wrap justify-center items-center gap-12">
          <img className={styles.whiteImg} src={ethImg.src} width="150" />
          <img className={styles.whiteImg} src={bnbImg.src} width="160" />
          <img className={styles.whiteImg} src={solImg.src} width="160" />
          <img className={styles.whiteImg} src={arbitrumImg.src} width="200" />
          <img className={styles.whiteImg} src={nearImg.src} width="120" />
          <img className={styles.whiteImg} src={fantomImg.src} width="120" />
          <img className={styles.whiteImg} src={avalancheImg.src} width="160" />
          <img className={styles.whiteImg} src={polyImg.src} width="160" />
          <img className={styles.whiteImg} src={aptosImg.src} width="90" />
        </div>
      </Container>
    </section>
  );
}
