import sv from './4sv-logo-white.png';
import gd10 from './GD10.png';
import nodeseed from './Nodeseeds_Logo_White_Transparent.png';
import au21 from './au21_capital.png';
import blackdragon from './blackdragon.png';
import blocksync from './blocksync-ventures.png';
import buildhodl from './build-hodl.png';
import chainlink from './chainlink.png';
import dutch from './dutch-crypto.png';
import exntc from './exntc.png';
import genblock from './genblock.png';
import genesis from './genesis-shards.png';
import lotus from './lotus_capital.png';
import lupa from './lupa.png';
import m6 from './m6.png';
import newfield from './newfield_fund_white.png';
import t3e from './t3e.png';
import twin from './twin-apex-white.png';
import wealth from './wealth-union-white.png';
import x21 from './x21.png';

export {
  sv,
  au21,
  blackdragon,
  blocksync,
  buildhodl,
  chainlink,
  dutch,
  exntc,
  genblock,
  genesis,
  gd10,
  lotus,
  lupa,
  wealth,
  newfield,
  x21,
  twin,
  t3e,
  m6,
  nodeseed,
};
