import { FaArrowDown } from 'react-icons/fa';

export default function SortHeader({
  sortField,
  sortAsc,
  children,
  id,
  onClick,
}: {
  sortField: string;
  sortAsc: boolean;
  children: React.ReactNode;
  id: string;
  onClick: (e: string) => void;
}) {
  return (
    <th
      id={id}
      className={`${
        sortField === id ? 'text-white' : ''
      } py-4 hover:cursor-pointer`}
      onClick={(e) => onClick(e.currentTarget.id)}>
      {children}{' '}
      {sortField === id && (
        <FaArrowDown
          className={`${sortAsc ? 'rotate-180' : ''} text-white inline`}
        />
      )}
    </th>
  );
}
