import { CSSProperties } from 'react';

export function HeroChainIcon(props: {
  alt: string;
  src: string;
  style: CSSProperties;
}) {
  const TooltipSpan = ({ children }) => (
    <>
      {/* prettier-ignore-attribute (className) */}
      <span
        data-tooltip={props.alt}
        className={`
          rounded-full
          inline-block
          relative

          after:bg-dark2 after:bg-opacity-30 
          after:block
          after:rounded-lg 
          after:text-sm after:text-gray-400 after:text-center
          after:content-[attr(data-tooltip)] after:invisible after:opacity-0 
          after:absolute 
          after:p-2 after:mt-1
          after:left-1/2 after:-translate-x-1/2
          after:ease-in after:duration-300

          after:hover:opacity-100 after:hover:visible
        `}>
        {children}
      </span>
    </>
  );

  return (
    <div className="mx-3 mb-12 sm:mb-0 ">
      <TooltipSpan>
        <div className="rounded-full h-10 w-10 lg:h-12 lg:w-12 flex items-center justify-center bg-dark2 bg-opacity-30">
          <img
            src={props.src}
            alt={props.alt}
            style={props.style}
            className="h-full rounded-full img-white opacity-40 hover:opacity-100"
          />
        </div>
      </TooltipSpan>
    </div>
  );
}
