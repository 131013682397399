/* eslint-disable @next/next/no-img-element */
import { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Countdown from 'react-countdown';
import { FaBullhorn, FaCopy, FaMedium, FaTelegramPlane } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { GradientButton } from 'src/features/landing/GradientButton';

import { TOKEN_ADDRESS } from '~config/addresses';
import {
  IDO_ACCESS_GUIDE_URL,
  MEDIUM_URL,
  TELEGRAM_ANNO_URL,
  TELEGRAM_URL,
  TWITTER_URL,
} from '~config/constants';
import { TPAD } from '~config/tokens';
import { TokenStatsNew } from '~features/landing/TokenStatsNew';
import { ChartDropdownButton } from '~features/launched';
import { pancakeImg } from '~features/launched/BuyButtons';
import { getTradeUrl } from '~utils';

import { HeroChainIcons } from './HeroChainIcons';
import styles from './Landing.module.css';
import CubeImg from './trustpad_cube.png';

const SALE_DATE = 1620237600 * 1000;
const LISTING_DATE = 1620306000 * 1000;

const LISTED = true;

export function Hero() {
  return (
    <div className="text-center xll:text-left xll:flex xll:flex-col xll:justify-center">
      <div className="mt-64 xll:mt-0 xll:py-4 2xl:py-16 xll:flex xll:justify-between">
        <div className="xll:pl-4">
          {Date.now() < SALE_DATE && (
            <Timer date={SALE_DATE} label="Sale opens in" />
          )}
          {Date.now() > SALE_DATE && Date.now() < LISTING_DATE && !LISTED && (
            <Timer date={LISTING_DATE} label="Listing in" />
          )}

          <h1 className="font-medium text-6xl text-center xll:text-left hero-title-shadow m-0 pb-7 animate-fade-in-2s">
            safest launchpad. multi-&#8288;chain
            <span className="text-[#e06cbd] no-text-shadow no-underline text-[1em] animate-fade-in-2s">
              .
            </span>
          </h1>

          <div className="mx-auto xll:mx-0">
            <p className="font-primary text-[31px] text-center xll:text-left animate-fade-in-3s">
              Join the best AI, GameFi & DeFi IDOs on TrustPad
            </p>

            <p className="font-primary text-center xll:text-left text-xl pt-4 m-auto xll:m-0 max-w-[839px] animate-fade-in-3s">
              Stake or farm $TPAD tokens to get early-access to top-tier IDOs in
              a safe and guaranteed way.
            </p>

            <div className="">
              <div className="mt-6 md:flex md:flex-wrap md:justify-center xll:justify-start">
                <div className="grid md:flex grid-cols-2">
                  <div className="mr-6 md:mr-6">
                    <GradientButton
                      href={getTradeUrl(TOKEN_ADDRESS, 'CAKE')}
                      gradientStyle="brand-bg-green">
                      <img
                        src={pancakeImg.src}
                        alt="PancakeSwap"
                        className="h-6 mr-2 my-1"
                      />{' '}
                      Buy TPAD
                    </GradientButton>
                  </div>
                  <div className="">
                    <GradientButton
                      href={IDO_ACCESS_GUIDE_URL}
                      gradientStyle="brand-bg">
                      <i className="las la-book la-2x mr-2 text-2xl"></i> How To
                      Start
                    </GradientButton>
                  </div>
                </div>
                <div className="flex flex-wrap justify-center md:justify-start mt-2 md:mt-0">
                  <div className="my-auto mx-3 md:ml-6 md:mr-0">
                    <a
                      href={TELEGRAM_URL}
                      className="text-main"
                      target="_blank"
                      rel="noreferrer">
                      <FaTelegramPlane size={'2em'} />
                    </a>
                  </div>
                  <div className="my-auto mx-3 md:ml-6 md:mr-0">
                    <a
                      href={TELEGRAM_ANNO_URL}
                      className="text-main"
                      target="_blank"
                      rel="noreferrer">
                      <FaBullhorn size={'2em'} />
                    </a>
                  </div>
                  <div className="my-auto mx-3 md:ml-6 md:mr-0">
                    <a
                      href={TWITTER_URL}
                      target="_blank"
                      className="text-main"
                      rel="noreferrer">
                      <FaXTwitter size={'2em'} />
                    </a>
                  </div>
                  <div className="my-auto mx-3 md:ml-6 md:mr-0">
                    <a
                      href={MEDIUM_URL}
                      target="_blank"
                      className="text-main"
                      rel="noreferrer">
                      <FaMedium size={'2em'} />
                    </a>
                  </div>
                </div>
              </div>

              <div className="flex justify-center xll:justify-start mt-5 md:mt-3 items-center gap-3 md:gap-6 flex-wrap">
                <div className="flex gap-2">
                  <ChartDropdownButton
                    chain="BSC"
                    address={TPAD.address}
                    pairAddress={TPAD.pairAddress}
                    defaultProvider="coingecko"
                    noDropdown
                  />
                  <ChartDropdownButton
                    chain="BSC"
                    address={TPAD.address}
                    pairAddress={TPAD.pairAddress}
                    defaultProvider="dextools"
                    noDropdown
                  />
                </div>

                <ContractLink />
              </div>
            </div>
          </div>
        </div>

        <div className="xll:flex xll:items-center xll:ml-8">
          <img
            src={CubeImg.src}
            alt="trustpad-cube-log"
            className="absolute top-72 lg:top-32 inset-x-0 block xll:static mx-auto xll:mx-0 h-48 xll:h-[22rem]"
          />
        </div>
      </div>
      <TokenStatsNew />
      <HeroChainIcons />
    </div>
  );
}

function ContractLink() {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1000);
    }
  }, [copied]);

  return (
    <CopyToClipboard text={TPAD.address} onCopy={() => setCopied(true)}>
      <a
        href="#"
        className="break-all no-underline bg-dark2 rounded-lg bg-opacity-30 text-gray-400 text-opacity-80 py-1 px-3 text-sm"
        onClick={(e) => e.preventDefault()}>
        <FaCopy className="inline" style={{ marginTop: '-3px' }} />{' '}
        {copied ? <span>copied</span> : <span>{TPAD.address}</span>}
      </a>
    </CopyToClipboard>
  );
}

function Timer({ date, label }: { date: string | number; label: string }) {
  return (
    <div className="flex items-center flex-col py-6">
      <h4 className="font-semibold text-2xl font-secondary tracking-wider">
        {label}
      </h4>
      <div className="mt-2 text-white">
        <Countdown date={date} renderer={renderer} daysInHours />
      </div>
    </div>
  );
}

function renderer({ days, hours, minutes, seconds, completed }) {
  if (completed) {
    return <span></span>;
  }

  return (
    <div className="flex items-start">
      <Piece label="Days">{String(days).padStart(2, '0')}</Piece>
      <Piece label="Hours">{String(hours).padStart(2, '0')}</Piece>
      <Piece label="Min">{String(minutes).padStart(2, '0')}</Piece>
      <Piece label="Sec" spacer={false}>
        {String(seconds).padStart(2, '0')}
      </Piece>
    </div>
  );
}

function Piece({
  label,
  spacer = true,
  children,
}: {
  label: string;
  spacer?: boolean;
  children: any;
}) {
  return (
    <div className="text-center">
      <div className="md:text-3xl text-2xl px-4 relative 2xl:text-4xl">
        <span className={spacer ? styles.value : ''}>{children}</span>{' '}
      </div>
      <div className="uppercase font-bold text-xs mt-1 font-serif">{label}</div>
    </div>
  );
}
